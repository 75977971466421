<template>
  <div>
    <!-- <div class="homePage" v-if="screenWidth > 700">
      <div class="title">
        <img
          class="img"
          src="@/assets/img/title_img.png"
          alt=""
          @click="jumpPage"
        />
        <div class="agreement_class">
          <a class="text" href="./userAgreement" target="_blank">用户协议</a>
          <a href="./privacyAgreement" target="_blank">隐私协议</a>
        </div>
      </div>
      <van-swipe
        v-if="screenWidth > 700"
        indicator-color="white"
        :touchable="true"
        class="van_swipe"
      >
        <van-swipe-item>
          <div class="content">
            <div class="left">
              <img class="img" src="@/assets/img/phone_img.png" alt="" />
            </div>
            <div class="right">
              <img class="img" src="@/assets/img/content_name_img.png" alt="" />
              <div class="text">
                <span class="programme">方案秒出</span>
                <span>极简交付</span>
              </div>
              <div class="text_title">
                <span>亲 爱 的 小 贝 伙 伴，这 是 专 为 你 而 做</span>
              </div>
              <div class="code_box">
                <img src="../assets/img/code2.png" alt="" />
              </div>
              <div class="bottom_text">
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>

      <div class="footer">
        <span>©2019-2022 阅动（广东）信息技术有限公司</span>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a target="_blank" href="/js/businessLicense.pdf">营业执照</a>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002333"
        >
          <img class="record_img" src="../assets/img/record_icon.png" alt="" />
          粤公网安备 44011202002333号
        </a>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <span @click="jumpRecord">粤ICP备2023005518号-1 </span>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <span>地址：广州市黄埔区光谱中路11号3栋1003 </span>
      </div>
    </div> -->
    <!-- <img  @dragstart.prevent style="width:100%;height:150px" :src="item.url" alt=""> -->



 <div class="homePage" v-show="screenWidth > 700">
    <div class="banner_box">
    <div class="banner_log">
    <img src="../assets/img/top_logo.png" alt="">
    </div>
    <div class="banner_text">
      <a  href="./userAgreement" target="_blank">用户协议</a>
      <a href="./privacyAgreement" target="_blank">隐私协议</a>
    </div>
    </div>
    <!-- <div class="left_bg3" >
    <img src="../assets/img/left_bg3.png" alt="" >
    </div>
     <div class="text_left">
    <img src="../assets/img/left_bg11.png" alt="" class="left_bg1" >
    <img src="../assets/img/left_bg2.png" alt="" class="left_bg2">
     </div>
    <img src="../assets/img/left_bg4.png" alt="" class="left_bg4"> -->
    <!-- <img src="@/assets/img/home_bg3.png" alt="" class="imgbox"> -->
    <!-- <div class="icon_text_box">
      <img src="../assets/img/xb_icon.png" alt="" class="xb_icon_img">
      <div class="text_box">
        <span>H3C小贝</span>
        <span>让品质网络无处不在</span>
      </div>
    </div> -->
      <div class="footer">
        <span>©2003-2024 新华三技术有限公司</span>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a target="_blank" href="/js/businessLicense.pdf">营业执照</a>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
       <!-- <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002333"
        >
          <img class="record_img" src="../assets/img/record_icon.png" alt="" />
          粤公网安备 44011202002333号
        </a>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> -->
        <span @click="jumpRecord">浙ICP备09064986号 </span>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <span>地址：杭州市滨江区长河路466号 </span>
      </div>
  </div>



    <van-swipe
      v-show="screenWidth < 700"
      class="store-pic-swipe"
      indicator-color="white"
      :touchable="false"
    >
      <van-swipe-item style="width: 100%">
        <div class="phone_page">
          <div class="content">
            <!-- <div class="xiaobei_title">
              <img src="../assets/img/xiaobei-title2.png" alt="" />
            </div> -->
            <div class="phone_content">
              <div class="content_top">
                <!-- <img
                  class="content_top_img"
                  src="../assets/img/content_name_img.png"
                  alt=""
                /> -->
                <img src="../assets/img/phonePage_icon.png" alt="" class="phone_page_icon">
                <div class="content_describe">
                  <!-- <span> 方案秒出 极简交付 </span> -->
                  <span>让品质网络无处不在</span>
                </div>
                <div class="content_down_bts" @click="goDownload">
                <span>点击下载</span>
                </div>
              </div>
              <!-- <img
                class="content_bottom_img"
                src="../assets/img/phone_img.png"
                alt=""
              /> -->
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="shade" v-show="shadeShow">
      <div>
        <!-- 预留提示图标位置 -->
        <div class="image-wrapper_6">
          <img class="label_1" src="../assets/img/jiantou.png" alt="" />
          <img class="image_8" src="../assets/img/zhexian.png" alt="" />
        </div>
        <span class="text_4">点击这里选择浏览器打开</span>
      </div>
    </div>
  </div>
</template>

<script>
// import pdf from 'vue-pdf'
import md5 from "js-md5";
// import Vconsole from 'vconsole';
export default {
  data() {
    return {
      href: "/businessLicense.pdf",
      screenWidth: "",
      isPhoneShow: true,
      shadeShow: false,
      systemType: "",
      resourceType: "",
    };
  },
  created() {
	// const vConsole = new Vconsole()
    // localStorage.setItem("token", "your_token_value");
    // 登录接口
    var xhr;
    if (window.XMLHttpRequest) {
      // code for modern browsers xhr = new
      xhr = new XMLHttpRequest();
    } else {
      // code for old IE browsers xhr = new
      xhr = new ActiveXObject("Microsoft.XMLHTTP");
    }
    // let xhr = new XMLHttpRequest();
    xhr.open("POST", "//api.eos.h3c.com/cbapp/v1.0/cbApp/api/login", true);
    // var token = localStorage.getItem("token");
    var time = new Date().getTime();
    var md5String = "cbapp" + "H3C!@bop**2022" + time;
    md5String = md5(md5String);
    // xhr.setRequestHeader("Authorization", "Bearer " + token);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (
        this.readyState === 4 &&
        (this.status === 200 || this.status === 304)
      ) {
        var responseJSON = JSON.parse(this.responseText);
        console.log(responseJSON);
        localStorage.setItem("token", responseJSON.token);
      }
    };
    xhr.send(JSON.stringify({ timeStr: time, md5Str: md5String }));
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;

    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    var ua = navigator.userAgent.toLowerCase();
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.shadeShow = true;
      }
    }
    // 如果是ios环境下微信分享的，下载量记录来源参数为1（微信）否则为2（浏览器）
    if (isIOS) {
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.resourceType = 1;
      } else {
        this.resourceType = 2;
      }
    }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        console.log(val,'val');
        if (val < 700) {
          this.isPhoneShow = false;
        } else {
          this.isPhoneShow = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  // components:{
  //   pdf,
  // },
  methods: {
    scan() {
      var link = document.createElement("a");
      link.href =
        "https://businesscard-bucket-730bc7e0.oss-hz-h3c.unicloudsrv.com/1677650660341_privateImg.png";
      //link.target="_blank";
      link.click();
    },
    //下载
    goDownload() {
		//console.info("进入下载，请继续")
        try{
          var u = navigator.userAgent;
          console.info("ua："+u)
          var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
          var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          var isVivo = u.match(/vivo/i);
          var isOppo = u.match(/oppo/i);
          var isOppoR15 = u.match(/pacm00/i);
          var isHuawei = u.match(/huawei/i);
          var isXiaomix = u.match(/mix/i);
          var isXiaomi = u.match(/mi/i);
          var isRedmi = u.match(/redmi/i);
          var isHonor = u.match(/honor/i);

		  //console.info("判断：isAndroid："+isAndroid+",isVivo:"+isVivo+",isOppo:"+isOppo+",isOppoR15:"+isOppoR15+",isHuawei:"+isHuawei+",isXiaomix:"+isXiaomix+",isXiaomi:"+isXiaomi+",isRedmi:"+isRedmi+",isHonor:"+isHonor)
          if (isIOS) {
           //console.info("苹果链接跳转");
            this.systemType = 1;
            window.location.href =
              "https://apps.apple.com/cn/app/h3cxiao-bei/id1660229016";
            // "https://apps.apple.com/cn/app/h3c%E5%B0%8F%E8%B4%9D/id1660229016";
            // this.resourceType = 1;
          }else if (isAndroid) {
            //console.info("安卓链接跳转");
            this.systemType = 2;
            this.resourceType = 2;
            if (isXiaomi || isXiaomix || isRedmi) {
              window.location.href = "mimarket://details?id=com.h3c.xiaobeiapp";
              //this.downloadApk();
            } else if (isOppo || isOppoR15) {
              window.location.href = "oppomarket://details?packagename=com.h3c.xiaobeiapp";
            } else if (isHuawei || isHonor) {
              window.location.href = "appmarket://details?id=com.h3c.xiaobeiapp";
            } else if (isVivo) {
              window.location.href = "vivoMarket://details?id=com.h3c.xiaobeiapp";
            } else {
              this.linkDownloadApk();
            }
          } else {
            //console.info("pc链接跳转");
            this.linkDownloadApk();
          }
        }catch(e){
            //console.info("eee:"+e);
            this.linkDownloadApk();
        }
      // app下载量
      let appDownload = new XMLHttpRequest();
      appDownload.open(
        "POST",
        "//api.eos.h3c.com/cbapp/v1.0/download/number/courseOrApp/addDownloadNumber", // 打包之前请切换正式环境
        true
      );
      var token = localStorage.getItem("token");
      appDownload.setRequestHeader("Content-type", "application/json");
      appDownload.setRequestHeader("Authorization", token);
      appDownload.onreadystatechange = function () {
        if (appDownload.readyState === 4 && appDownload.status === 200) {
          console.log(appDownload.responseText);
        }
      };
      appDownload.send(
        JSON.stringify({
          resourceType: this.resourceType,
          systemType: this.systemType,
          operateType: "APP",
        })
      );
    },
    linkDownloadApk(){
       // 执行下载并安装 APK 的操作
      var link = document.createElement("a");
      link.href =
        "https://cbapp-bucket-1307bf32.oss-hz-h3c.unicloudsrv.com/app/xiaobeiapp.apk";
      link.download = "小贝app";
      link.click();
    },
    downloadApk(){
        var obj = this;
        // 如果打开应用商店失败，备用方案：下载并安装 APK
        setTimeout(function() {
          // 如果用户没有下载 APK，可以提示用户点击下载
          if (!confirm("若系统未打开应用商店，请点击”确认“下载并安装！")) {
            return;
          }
          obj.linkDownloadApk();
        }, 1000);  // 在 3000 毫秒后执行备用方案
    },
    //用户协议
    jumpOutsideLink(url, fileName) {
      window.open(url, fileName);
    },
    handlePostMessage() {
      console.log(213);
    },
    jumpPage() {
      this.$router.push({
        path: "/phonePage",
      });
    },
    jumpRecord() {
      window.location.href = "https://beian.miit.gov.cn";
    },
  },
};
</script>

<style lang="less" scoped>

// @media screen and (max-width: 770px) {
//   .icon_text_box {
//     position: fixed;
//     left: 28%;
//     top: 16%;
//     display: flex;
//     align-items: center;
//   }
//   .banner_log{
//     width: 166.8px;
//     height: 38.4px;
//     margin-left: 28%;
// }
// }
// @media screen and (min-width: 770px) and (max-width: 1024px) {
//   .icon_text_box {
//     position: fixed;
//     left: 21%;
//     top: 16%;
//     display: flex;
//     align-items: center;
//   }
//     .banner_log{
//     width: 166.8px;
//     height: 38.4px;
//     margin-left:21%;
// }
// }
// @media screen and (min-width: 1024px) and (max-width: 1345px) {
//   .icon_text_box {
//     position: fixed;
//     left: 18%;
//     top: 16%;
//     display: flex;
//     align-items: center;
//   }
//       .banner_log{
//     width: 166.8px;
//     height: 38.4px;
//     margin-left:16%;
// }
// }
// @media screen and (min-width:1345px) {
//   .icon_text_box {
//     position: fixed;
//     left: 14.5%;
//     top: 16%;
//     display: flex;
//     align-items: center;
//   }
//       .banner_log{
//     width: 166.8px;
//     height: 38.4px;
//     margin-left:14.5%;
// }
// }
.homePage {
  width: 100vw;
  height: 100vh; 
  overflow: hidden;
  background: url("@/assets/img/home_bg6.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: left;
.imgbox{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // width: 100%;
  // height: 100%;
  object-fit: cover;
}

 

.icon_text_box{
  // position: fixed;
  // left: 14.5%;
  // top: 16%;
  // display: flex;
  // align-items: center;
  img{
    width: 90px;
    height: 90px
  }
  .text_box{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    span{
      font-weight: 500;
      font-size: 24px;
      color: #2A2F66;
      text-align: left;
    }
    span:last-child{
      font-weight: bold;
      font-weight: 600;
      font-size: 24px;
      color: #2A2F66;
      text-align: left;
      font-family: Microsoft YaHei, Microsoft YaHei;
    }
  }
}


  .left_bg3{
    // width: 240px;
    // height: 60px;
    // margin-top: 52px;
    // margin-bottom: 40px;
    margin-left: 14.5%;
  }
  .left_bg4{
    width: 136px;
    height: 58px;
    position: absolute;
    top: 30%;
    left: -48px;
  }

.banner_box{
  width: 100%;
  // min-width: 1920px;
  height: 80px;
  display: flex;
  align-items: center;
  //  background: linear-gradient(to right, white 40%, transparent 20%);
  .banner_log{
    width: 166.8px;
    height: 38.4px;
    margin-left: 14.5%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .banner_text{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 270px;
  a{
    font-size: 14px;
    color: #666666;
  }
  a:first-child{
    margin-right: 56px;
  }
  }
}
  .pdf {
    position: absolute;
    z-index: 1000000;
  }
  .title {
    width: 100%;
    height: 8%;
    line-height: 8%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img {
      width: 119px;
      height: 20px;
      margin: 0 62px 0 40px;
    }
    .agreement_class {
      color: #fff;
      font-size: 16px;
      a {
        text-decoration: none;
        color: #fff;
      }
      .text {
        padding-right: 30px;
      }
    }
  }
  .content {
    // width: 100%;
    min-width: 650px;
    height: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5%;
    .left {
      // width: 30%;
      height: 450px;
      // min-width: 35%;
      // height: 100%;
      // background-image: url('@/assets/img/phone_img.png');
      // background-size: 100% 100%;
      .img {
        // width: 500px;
        height: 450px;
        // height: 50%;
        min-height: 46%;
      }
    }
    .right {
      // width: 31%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .img {
        // width: 300px;
        width: 300px;
        // height: 16%;
        margin-top: 50px;
      }
      .text {
        width: 60%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 auto;
        font-size: 30px;
        color: #000;
        .programme {
          padding-right: 20px;
        }
      }
      .type_img {
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        :first-child {
          margin-right: 15px;
        }
        img {
          width: 47%;
        }
      }
      .bottom_text {
        width: 100%;
        font-size: 37px;
        text-align: left;
        margin-top: 80px;
        font-size: 30px;
        color: #000;
        :last-child {
          text-align: right !important;
        }
      }
    }
  }
  .footer {
    width: 100%;
    // height: 60%;
    text-align: center;
    position: absolute;
    bottom: 24px;
    // color: #2c2c2c;
    color: #666666;
    font-size: 14px;
    a {
      text-decoration: none;
      // color: #000;
    color: #666666;
      .record_img {
        margin-bottom: -5px;
      }
    }
  }
}
.phone_page {
  height: 100vh;
  box-sizing: border-box;
  // background: url("../assets/img/phone_backGround.png");
  background: url("../assets/img/phonePage_bg.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  .header {
    width: 100%;
    height: 7%;
    background: linear-gradient(90deg, #00a6de 0%, #01cfee 50%, #33e2b9 100%);
    position: relative;
    margin: 0 auto;
    line-height: 44px;
    .header_icon {
      width: 11px;
      height: 19px;
      position: absolute;
      top: 25%;
      left: 17px;
    }
  }
  .content {
    width: 100%;
    // min-width: 1000px;
    height: 100vh;
    //   background-image: url("../assets/img/phone_backGround.png");

    overflow: hidden;
    .phone_content {
      // padding: 0 83px;
      // margin: 101px auto 0;
      margin: 48px auto 0;
      .content_top {
        width: 100%;
        box-sizing: border-box;
        // margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
          .phone_page_icon{
            width: 72px;
            height: 72px;
          }
        .content_top_img {
          width: 100%;
          margin: 0 auto;
        }
        .content_describe {
          width: 204px;
          height: 33px;
          // margin: 5px auto 0;
          margin: 17px auto 0;
          // font-size: 24px;
          // font-weight: 400;
          // color: #333333;
          line-height: 33px;
          font-weight: 600;
          font-size: 20px;
          color: #2A2F66;
        }
        .content_down_bts {
          width: 191px;
          height: 44px;
          // margin: 24px auto 0;
          margin: 40px 0 0 0;
          // background: #00a6de;
          box-shadow: 0px 2px 5px 0px rgba(199, 213, 226, 0.5);
          border-radius: 22px;
          line-height: 44px;
          color: rgba(255, 255, 255, 1);
          // font-size: 23px;
          // font-family: PingFangSC-Medium;
          // font-weight: 500;
          background: linear-gradient( 90deg, #006CCC 0%, #3DD5D5 100%);
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      // .content_bottom {

      .content_bottom_img {
        margin-top: 65px;
        // height: calc(100vh-321px);
        max-width: 85%;
        min-height: 250px;
      }
      // }
    }
  }
}
.phone_page_btn {
  display: flex;
  .content_btn {
    margin: auto;
  }
}
.van_swipe {
  height: 92%;
}
.shade {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.image-wrapper_6 {
  width: 50%;
  margin-left: 46%;
  margin-top: 25px;
}

.label_1 {
  width: 20%;
  /* height: 46px; */
  margin-top: 150px;
}

.image_8 {
  height: 207px;
  margin-bottom: 13px;
  width: 80%;
}
.text_4 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 17px;
  font-family: PingFangSC-Medium;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  line-height: 24px;
  margin: 9px 87px 0 101px;
}
.text_title {
  margin-top: 10px;
  width: 600px;
  height: 39px;
  font-size: 20px;
  color: #000;
  font-weight: 400;
}
.code_box {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
  }
}
.xiaobei_title {
  height: 50px;
  line-height: 60px;
  text-align: center;
  background: linear-gradient(to right, #01a7de, #10c5dd);
  img {
    width: 140px;
    margin-left: 30px;
  }
}
</style>
